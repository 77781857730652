<script>
import { reactive } from 'vue';
import { useRouter } from 'vue-router';
import { useMutation } from '@urql/vue';
import Multiselect from '@suadelabs/vue3-multiselect';
import FormInput from '@/components/form/FormInput.vue';
import Button from '@/components/Button.vue';
import CreateUserMutation from '@/queries/CreateUser';

export default {
  components: {
    FormInput,
    Button,
    Multiselect,
  },
  setup() {
    const router = useRouter();
    const model = reactive({
      name: '',
      email: '',
      role: '',
      password: '',
      password2: '',
      globalError: '',
    });

    const errors = reactive({
      name: '',
      email: '',
      role: '',
      password: '',
      password2: '',
    });

    const resetErrors = () => {
      model.globalError = '';
      Object.keys(errors).forEach((key) => {
        errors[key] = '';
      });
    };

    const roles = ['user', 'admin', 'super'];

    const checkErrors = () => {
      resetErrors();
      let hasErrors = false;
      if (model.name.length <= 0) {
        errors.name = 'This field is required.';
        hasErrors = true;
      }
      if (model.email.length <= 0) {
        errors.email = 'This field is required.';
        hasErrors = true;
      }
      if (!roles.includes(model.role)) {
        errors.role = 'Role must be selected';
        hasErrors = true;
      }
      if (model.password.length < 6) {
        errors.password = 'Password must be at least 6 characters long.';
        hasErrors = true;
      }
      if (model.password !== model.password2) {
        errors.password2 = 'Passwords do not match.';
        hasErrors = true;
      }
      if (!/\w+@\w+.\w+/.test(model.email)) {
        errors.email = 'Please enter a valid email.';
        hasErrors = true;
      }
      return hasErrors;
    };

    const { executeMutation: createUser } = useMutation(CreateUserMutation);

    const saveUser = () => {
      const hasErrors = checkErrors();

      if (hasErrors) {
        return;
      }

      const input = {
        name: model.name,
        email: model.email,
        role: model.role,
        password: model.password,
      };

      createUser({ input })
        .then((result) => {
          if (result.errors) {
            model.globalError = 'Something happened.';
          }
          router.push({ name: 'admin-users' }, () => {});
        });
    };

    return {
      model,
      saveUser,
      errors,
      roles,
    };
  },
};
</script>
<template>
  <div class="new-user-form container">
    <div class="header">
      <h1>New User</h1>
    </div>
    <div class="error-banner" v-if="model.globalError.length > 0">
      {{model.globalError}}
    </div>
    <div class="form">
      <div class="form-input">
        <FormInput
          label="Name"
          v-model="model.name"
          :error="errors.name.length > 0"
          :errorMessage="errors.name"
        />
      </div>
      <div class="form-input">
        <FormInput
          label="Email"
          v-model="model.email"
          :error="errors.email.length > 0"
          :errorMessage="errors.email"
          type="email"
        />
      </div>
      <div class="form-input">
        <label>Role</label>
        <Multiselect
          :options="roles"
          v-model="model.role"
        />
        <span
          class="error"
          v-if="errors.role.length > 0"
        >
          {{errors.role}}
        </span>
      </div>
      <div class="form-input">
        <FormInput
          label="Password"
          v-model="model.password"
          :error="errors.password.length > 0"
          :errorMessage="errors.password"
          type="password"
        />
      </div>
      <div class="form-input">
        <FormInput
          label="Password (confirm)"
          v-model="model.password2"
          :error="errors.password2.length > 0"
          :errorMessage="errors.password2"
          type="password"
        />
      </div>
    </div>
    <div class="footer">
      <Button @click="saveUser">
        Save
      </Button>
    </div>
  </div>
</template>
<style src="@suadelabs/vue3-multiselect/dist/vue3-multiselect.css"></style>
<style lang="scss">
  .multiselect__tags {
    height: 42px;
  }
  .multiselect__single {
    margin-top: 2px;
  }
  .multiselect__option--highlight {
    background: $BRAND_PRIMARY !important;
    &:after {
      background: $BRAND_PRIMARY !important;
    }
  }
</style>
<style lang='scss' scoped>
  .error-banner {
    background: rgba($ERROR_COLOR, .2);
    border-left: 5px solid $ERROR_COLOR;
    padding: 0.75rem 1rem;
    margin-bottom: 1rem;
    color: $ERROR_COLOR;
  }

  .container {
    max-width: $containerWidth;
    margin: 0 auto;
    padding: 1rem;
    padding-top: 4rem;
  }
  h1 {
    @include header;
    text-align: left;
    padding: 1rem 0;
    font-size: 1.5rem;
    flex: 1;
  }

  .error {
    color: $ERROR_COLOR;
  }

  .form {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 1rem;

    @include tablet {
      grid-template-columns: 1fr;
    }
  }

  .two-col {
    grid-column: 1 / 3;
    @include tablet {
      grid-column: 1;
    }
  }

  .footer {
    display: flex;
    flex-direction: row-reverse;
    margin-top: 1rem;
  }
  .error {
    color: $ERROR_COLOR;
  }
</style>
