import { gql } from 'urql/core';

export default gql`
  mutation createUser($input: CreateUserInput!) {
    createUser(input: $input) {
      id
      email
      name
      role
    }    
  }
`;
