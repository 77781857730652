import { render } from "./UserCreate.vue?vue&type=template&id=0bbe237a&scoped=true"
import script from "./UserCreate.vue?vue&type=script&lang=js"
export * from "./UserCreate.vue?vue&type=script&lang=js"

import "@suadelabs/vue3-multiselect/dist/vue3-multiselect.css?vue&type=style&index=0&lang=css"
import "./UserCreate.vue?vue&type=style&index=1&id=0bbe237a&lang=scss"
import "./UserCreate.vue?vue&type=style&index=2&id=0bbe237a&lang=scss&scoped=true"
script.render = render
script.__scopeId = "data-v-0bbe237a"

export default script